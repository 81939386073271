<template>

 <b-card>
      <div class="d-flex align-items-center">
              <div class="info">
                <h3>Soon</h3>
              </div>
      </div>
  <hr>

</b-card>

</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  data: function() {
    return {
     
    }
  },
  components: {
    BCard,
    BCardText,
    
  },
  computed: {
   
  },
  methods: {
  
},

}
</script>

<style>

</style>
